#content-wrapper {
	width: 120rem;
	margin: 0 auto;
	padding-top: 8rem;
	padding-bottom: 2rem;
}

footer {
	border-top: 1px dashed $white;
	padding: 4rem 3rem 2rem;
	margin-top: 6rem;
}

@include media("<=desktop") {
	#content-wrapper {
		width: 100%;
	}
}
