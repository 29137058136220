.me {
	border-radius: 50%;
	width: 30rem;
	shape-outside: circle(50%);
	object-fit: cover;
}

@include media(">=desktop") {
	.me {
		float: right;
		margin: 4rem;
	}
}

@include media("<desktop") {
	.me {
		display: block;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 4rem;
	}
}

@include media("<tablet") {
	.me {
		width: 75vw;
	}
}
