* {
	-webkit-font-feature-settings: "liga" on, "calt" on;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
}

body {
	font-family: monospace;
	font-size: 2.3rem;
	line-height: 3.3rem;
	color: $white-dark;
}

html.wf-active body {
	font-family: 'JetBrains Mono', monospace;
	font-size: 1.9rem;
}

a {
	color: $white;
	font-weight: bold;
	text-decoration: none;
}

a:hover {
	text-decoration: underline;
}

dt {
	color: $white;
	font-weight: bold;
}

footer {
	font-size: 1.6rem;
}
