h1, h2, h3, h4 {
	color: $white;
}

h1 {
	font-size: 6rem;
	margin-bottom: 2rem;
}

h2 {
	font-size: 3.9rem;
	padding-bottom: 0.4rem;
	padding-left: 1rem;
	margin-bottom: 4rem;
	border-bottom: 1px solid $white;

	small {
		font-size: 2rem;
		float: right;
		padding-right: 1rem;

		a {
			font-weight: normal;
		}
	}
}

h3 {
	font-size: 2.3rem;
	margin-bottom: 4rem;
}

h4 {
	font-weight: bold;
	text-transform: uppercase;
	margin-bottom: 2.5rem;

	small {
		font-weight: normal;
		text-transform: none;
	}
}

@include media("<desktop") {
	h1 {
		line-height: 7rem;
	}

	h2 {
		line-height: 5rem;
	}

	h3 {
		margin-bottom: 6rem;
		margin-left: 1rem;
		margin-right: 1rem;
	}

	.headline {
		text-align: center;
	}
}
