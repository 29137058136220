:root {
	font-size: 62.5%;
}

@import "~minireset.css/minireset";
@import "../node_modules/fontsource-jetbrains-mono/index.css";
@import "../node_modules/include-media/dist/include-media";
@import "../node_modules/flexboxgrid/dist/flexboxgrid.css";

@import "core/colors";
@import "core/body";
@import "core/text";
@import "core/headline";

@import "layout/layout";

@import "components/me";
@import "components/skills";

.box p {
	text-wrap: pretty;
	overflow-wrap: break-word;
}
