body {
	background-color: $gray;
}

section {
	padding-right: 2.5rem;
	padding-left: 2.5rem;
	padding-bottom: 3.5rem;
}

article > section {
	padding-bottom: 0;
}

article {
	margin-bottom: 3rem;
}

p, ul {
	padding-bottom: 2.5rem;
}

li {
	padding-bottom: 0.3rem;
}

li:before {
	content: "•";
	padding-right: 1rem;
}

dt, dd {
	display: inline;
}

.row {
	margin-bottom: 2rem;
}
